import React, { useEffect, useState, useRef } from "react";
import { useHttpGet } from "../../components/hooks/useHttp";
import Product from "../../components/product/Product";
import Favorite from "../../components/favorite/Favorite";
import Title from "../../components/title/Title";
import styles from "./dashboard.module.scss";
import Carousel from "react-elastic-carousel";
import { Spin } from 'antd';
import * as utils from "../../utils/utils";
import * as favUtils from "../../utils/favorite.utils";
import * as constants from "../../../src/utils/constant";
// import 'antd/dist/antd.css';
// import { Modal, Button } from 'antd';
import { Modal, Button, Form } from "react-bootstrap";
const Dashboard = (props) => {

  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [acknowledgeData, setAckData] = useState(null);
  const [isAckUpdated, setAckFlagUpdate] = useState(false);
  const [defaultSelectedRegion, setDefaultSelectedRegion] = useState("NA");//EMEA Release-
  const [selectedRegion, setSelectedRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release-
  const [dashboardData, setDashboardData] = useState(null);//EMEA Release-
  const [subRegionData, setSubRegionData] = useState(null);//EMEA Release-

  const mySRCarousel = useRef(null);
  const myCustCarousel = useRef(null);
  const myPDCarousel = useRef(null);
  const myFavCarousel = useRef(null);

  const viewFavorites = (element) => {
    console.log("ViewFavorites in Dashboard", element);
    const { productDivision, customer, sbs, cxScoreDetails, breadcrumb, kpi, nba, group, sasParent, subregion } = element.InputParameters;
    props.setSubRegion({});
    if (element.ViewType === 1) {
      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation =  Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to PD Detail
        props.setProductDivision(productDivision)
        props.history.push('/dashboard/productdivisiondetail');
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
        props.history.push("/dashboard/customerdetail");
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to PD Detail
        props.setProductDivision(productDivision)
        sbs.primaryRecommendation = Array.isArray(sbs.primaryRecommendation) && sbs.primaryRecommendation.length>0  ?sbs.primaryRecommendation[0]: sbs.primaryRecommendation;//fix for navigation issue from dashboard Favorite to SBS Detail
        props.setSelectedSBS(sbs)
        props.history.push('/dashboard/sbsdetail')
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer);
        props.history.push("/dashboard/customerdetail");
      } else if(!utils.isEmpty(subregion) && utils.isEmpty(productDivision) && utils.isEmpty(sbs) && utils.isEmpty(customer)){
        subregion.primaryRecommendation = Array.isArray(subregion.primaryRecommendation) && subregion.primaryRecommendation.length>0 ?  subregion.primaryRecommendation[0] : subregion.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setSubRegion(subregion);
        props.history.push("/dashboard/subregiondetail");
      }
      else {
        group.primaryRecommendation = Array.isArray(group.primaryRecommendation) && group.primaryRecommendation.length>0 ?  group.primaryRecommendation[0] : group.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setGroup(group)
        props.history.push('/dashboard/groupdetail');
      }
    } else if (element.ViewType === 2 || element.ViewType === 9) {
      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        sbs.primaryRecommendation = Array.isArray(sbs.primaryRecommendation) && sbs.primaryRecommendation.length>0 ? sbs.primaryRecommendation[0]: sbs.primaryRecommendation;//fix for navigation issue from dashboard Favorite to SBS Detail
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      } else if(!utils.isEmpty(subregion) && utils.isEmpty(productDivision) && utils.isEmpty(sbs) && utils.isEmpty(customer) ){
        subregion.primaryRecommendation = Array.isArray(subregion.primaryRecommendation) && subregion.primaryRecommendation.length>0 ?  subregion.primaryRecommendation[0] : subregion.primaryRecommendation ;
        props.setSubRegion(subregion);
      }
      else{
        group.primaryRecommendation = Array.isArray(group.primaryRecommendation) && group.primaryRecommendation.length>0 ?  group.primaryRecommendation[0] : group.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setGroup(group)
      }
      props.setCXScoreData(cxScoreDetails);
      props.setBreadCrumb(breadcrumb);
      props.setSASParent(sasParent);
      if (element.ViewType === 2) {
        if (sasParent === 'cx')
          props.history.push("/dashboard/score");
        else
          props.history.push("/dashboard/score/cas");
      } else {
          props.history.push("/dashboard/cas-childview");
      }
    } else if (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5) {
      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion) ) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        sbs.primaryRecommendation = Array.isArray(sbs.primaryRecommendation) && sbs.primaryRecommendation.length>0 ? sbs.primaryRecommendation[0]: sbs.primaryRecommendation;//fix for navigation issue from dashboard Favorite to SBS Detail
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      }else if(!utils.isEmpty(subregion) && utils.isEmpty(productDivision) && utils.isEmpty(sbs) && utils.isEmpty(customer))
      {
        subregion.primaryRecommendation = Array.isArray(subregion.primaryRecommendation) && subregion.primaryRecommendation.length>0 ?  subregion.primaryRecommendation[0] : subregion.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setSubRegion(subregion);
      }else{
        group.primaryRecommendation = Array.isArray(group.primaryRecommendation) && group.primaryRecommendation.length>0 ?  group.primaryRecommendation[0] : group.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setGroup(group)
      }

      props.setCXScoreData(cxScoreDetails);
      props.setSASParent('cx');
      props.setKPI(kpi)
      props.setBreadCrumb(breadcrumb);
      if (element.ViewType === 3) {
        props.history.push("/dashboard/primaryview");
      } else if (element.ViewType === 4) {
        props.history.push("/dashboard/childview");
      } else {
        props.history.push("/dashboard/history");
      }
    } else if (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8) {
      // NBA views

      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        sbs.primaryRecommendation = Array.isArray(sbs.primaryRecommendation) && sbs.primaryRecommendation.length>0 ? sbs.primaryRecommendation[0]: sbs.primaryRecommendation;//fix for navigation issue from dashboard Favorite to SBS Detail
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        productDivision.primaryRecommendation = Array.isArray(productDivision.primaryRecommendation) && productDivision.primaryRecommendation.length>0 ?  productDivision.primaryRecommendation[0] : productDivision.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setProductDivision(productDivision)
        customer.primaryRecommendation = Array.isArray(customer.primaryRecommendation) && customer.primaryRecommendation.length>0 ?  customer.primaryRecommendation[0] : customer.primaryRecommendation ;//fix for navigation issue from dashboard Favorite to 
        props.setCustomer(customer)
      } else if(!utils.isEmpty(subregion) && utils.isEmpty(productDivision) && utils.isEmpty(sbs) && utils.isEmpty(customer))
      {
        //SubRegion
        subregion.primaryRecommendation = Array.isArray(subregion.primaryRecommendation) && subregion.primaryRecommendation.length>0 ?  subregion.primaryRecommendation[0] : subregion.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setSubRegion(subregion);
      } else {
        group.primaryRecommendation = Array.isArray(group.primaryRecommendation) && group.primaryRecommendation.length>0 ?  group.primaryRecommendation[0] : group.primaryRecommendation ;//fix for navigation issue from dashboard Favorite
        props.setGroup(group)
      }

      props.setNBA(nba)
      props.setNBABreadCrumb(breadcrumb);
      if (element.ViewType === 6) {
        props.history.push("/dashboard/nba/primaryview");
      } else if (element.ViewType === 7) {
        props.history.push("/dashboard/nba/childview");
      } else {
        props.history.push("/dashboard/nba/history");
      }
    }
  }

  const processData = (fetchedData, region) => {
    //code Added for EMEA release - to fetch specific group based on region selection
    const selectedRegionIndex = fetchedData.group.findIndex((item) => item.company_region.toLowerCase() === region.toLowerCase())
    const group = fetchedData.group[selectedRegionIndex]
    //const { group } = fetchedData;  //commented for EMEA release
 
    group.cxBenchmark = group.cxGroupBenchmark;
    group.casBenchmark = group.casGroupBenchmark;
    group.primaryRecommendation = Array.isArray(group.primaryRecommendation) ? group.primaryRecommendation[0] : group.primaryRecommendation;
    const productDivisions = [...group.productDivisions];
    productDivisions.forEach((element) => {
      element.name = element.name.toUpperCase();
      element.cxBenchmark = group.cxProductDivisionBenchmark;
      element.casBenchmark = group.casProductDivisionBenchmark;
      element.primaryRecommendation = Array.isArray(element.primaryRecommendation) ? element.primaryRecommendation[0] : element.primaryRecommendation;//EMEA Release- Added this
    });
    productDivisions.sort(utils.sorting('code', 'string', true))
    
    const customers = [...group.customers];
    customers.sort(utils.sorting('name', 'string', true))
    customers.forEach((element) => {
      element.name = utils.capitalize(element.name.trim())
      element.cxBenchmark = group.cxCustomerBenchmark;
      element.casBenchmark = group.casCustomerBenchmark;
    });
    return { group, productDivisions, customers  }
    
  };

  const showGroupDetail = (item) => {
    props.setGroup(item)
    props.setSubRegion({})
    props.history.push("/dashboard/groupdetail");
  }

  const showCustomerDetail = (item) => {
    props.setCustomer(item)
    props.setSubRegion({})
    props.history.push("/dashboard/customerdetail");
  }

  const setProductDivision = (item) => {
    props.setProductDivision(item)
    props.setSubRegion({})
    props.history.push("/dashboard/productdivision");
  }

  const setSubRegion = (item) => {
    props.setSubRegion(item)
    props.history.push("/dashboard/subregiondetail");
  }

  async function getFavorites() {
    try {
      setLoading(true)
      const favorites = await favUtils.getFavorites()
      const processedFav = await favUtils.processFavorites(favorites)
      console.log("ProcessedFav--Region Selected", processedFav, JSON.parse(sessionStorage.getItem("selectedRegion")))
      const filteredProcessedFav = processedFav.filter((item) => item.region.toLowerCase() === JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase())
      console.log("FilteredProcessFav", filteredProcessedFav)
      //showHideLeftCustArrow();
      //showHideRightCustArrow();
      myCustCarouselChange();
      mySubRegionCarouselChange();
      setFavorites(filteredProcessedFav)
      props.setFavorite(favorites);
      //myFavCarouselChange();
      setLoading(false);
      handleShow();
    } catch (err) {
      setLoading(false)
    }
  }

  //EMEA Release-- code for new JSON structure changes
  const getGroupData = (result, region) => {
    const group = result.hits.hits[0];
    const customerObject = group.inner_hits['group.customers'].hits;
    let new_array = []
    if (customerObject && customerObject.hits && customerObject.hits.length > 0) {
      const customerFilteredArray = customerObject.hits.filter((item) => item.fields['group.customers.company_region.keyword'][0] === region)
      new_array = customerFilteredArray.map(function callback(element) {
        let custObj = {};
        custObj.company_region = (element.fields['group.customers.company_region.keyword']) ? element.fields['group.customers.company_region.keyword'][0] : '-';
        custObj['name'] = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
        custObj.cxScore = (element.fields['group.customers.cxScore']) ? element.fields['group.customers.cxScore'][0] : '-';
        custObj.lastQuarterCxScore = (element.fields['group.customers.lastQuarterCxScore']) ? element.fields['group.customers.lastQuarterCxScore'][0] : '-';
        custObj.code = (element.fields['group.customers.code.keyword']) ? element.fields['group.customers.code.keyword'][0] : '-';
        custObj.lastQuarterCas = (element.fields['group.customers.lastQuarterCas']) ? element.fields['group.customers.lastQuarterCas'][0] : '-';
        custObj.responseCount = (element.fields['group.customers.responseCount']) ? element.fields['group.customers.responseCount'][0] : '-';
        custObj.id = (element.fields['group.customers.id']) ? element.fields['group.customers.id'][0] : '-';
        custObj.name = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
        custObj.cas = (element.fields['group.customers.cas']) ? element.fields['group.customers.cas'][0] : '-';
        //custObj.volatility = (element.fields['group.customers.volatility.keyword']) ? element.fields['group.customers.volatility.keyword'][0] : '-';
        custObj.primaryRecommendation = (element.fields['group.customers.primaryRecommendation.keyword']) ? element.fields['group.customers.primaryRecommendation.keyword'][0] : '-';
        custObj.secondaryRecommendations = (element.fields['group.customers.secondaryRecommendations.keyword']) ? element.fields['group.customers.secondaryRecommendations.keyword'] : [];
        custObj.whiteGlove = (element.fields['group.customers.whiteGlove.keyword']) ? element.fields['group.customers.whiteGlove.keyword'][0] : '-';
        return custObj
      })
    }

    const selectedRegionIndex = group['_source'].group.findIndex((item) => item.company_region === region)
    group['_source'].group[selectedRegionIndex].customers = new_array;
    return group['_source'];
  }
  //On initial load - fetch NA and EMEA data including customers data
  useEffect(() => {
    //EMEA Release- - Default selection to NA on load
    let region = selectedRegion ? selectedRegion : defaultSelectedRegion
    sessionStorage.setItem("selectedRegion", JSON.stringify(region));
    setSelectedRegion(region)  //EMEA Release- ends here
    const input = {
      name: constants.Group_Dashboard_Index,
      queryParams: {
        "_source": {
          "excludes": ["*.customers"]
        },
        "query": {
          "nested": {
            "path": "group.customers",
            "query": {
              "match": {
                "group.customers.whiteGlove": "true"
              }
            },
            "inner_hits": {
              "_source": false,
              "size": 100,//modified for EMEA release
              "docvalue_fields": [
                "group.customers.company_region.keyword",//added for EMEA release
                "group.customers.code.keyword",
                "group.customers.name.keyword",
                "group.customers.id",
                "group.customers.cxScore",
                "group.customers.lastQuarterCxScore",
                "group.customers.lastQuarterCas",
                "group.customers.responseCount",
                "group.customers.cas",
                //"group.customers.volatility.keyword",
                "group.customers.primaryRecommendation.keyword",
                "group.customers.secondaryRecommendations.keyword",
                "group.customers.whiteGlove.keyword"
              ]
            }
          }
        }
      }
    }
    setIsLoading(true)
      utils.getDataES('-elasticsearch', 'POST', input)
      .then(resp => {
        setDashboardData(resp.data);
        parseData(resp.data, region);
        //showHideLeftCustArrow();
        //showHideRightCustArrow();
        myCustCarouselChange();
        //myFavCarouselChange();
      }).catch(err => setIsLoading(false))
    initialLoadArrow();
    props.setCustomer({})
    props.setSelectedSBS({})
    props.setProductDivision({})
    props.setSubRegion({})//added for EMEA release
    getFavorites();
  }, [])

  useEffect(()=>{
    console.log("useEffect favorites")
    myFavCarouselChange();
  },[favorites.length]);


  //fetch Subregion API
  const processSubRegionData = () =>{
      let subRegion;
      const subRegionInput ={
        name: constants.SubRegion_Dashboard_Index,
        queryParams:{}
      }
      setIsLoading(true)
      utils.getDataES('-elasticsearch', 'POST', subRegionInput)
        .then(subRegresp => {
         subRegion = subRegresp.data.hits.hits[0]['_source'].group;
         subRegion.forEach((element) => {
         // element.code = element.name.toUpperCase();
          element.companycodes = Array.isArray(element.companycodes) ? element.companycodes.join(","): "" ;
          element.code = utils.capitalize(element.code.trim())
          element.cxBenchmark = element.cxProductDivisionBenchmark;
          element.casBenchmark = element.casProductDivisionBenchmark;
          element.primaryRecommendation = Array.isArray(element.primaryRecommendation)? element.primaryRecommendation[0] : element.primaryRecommendation;
        });
        subRegion.sort(utils.sorting('code', 'string', true))
        props.setSubRegion(subRegion)
        setSubRegionData(subRegion)
        setIsLoading(false)
        }).catch(err => setIsLoading(false))
  }

  /*EMEA Release- - New method added which calls subsequent methods to process based on region selected */
  const parseData = (dbData, region) => {
    if(JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase()==='emea'){
      processSubRegionData();
    }
    else{
      setSubRegionData([])
    }

    let groupESData = getGroupData(dbData, region);
    let result = processData(groupESData, region);
    setData(JSON.parse(JSON.stringify(result)))
    setIsLoading(false);
  }

  /*EMEA Release- - Added handler for region selection- radiobutton  */
  const handleRegionChange = (reg) => {
    mySRCarousel?.current?.goTo(0);
    myCustCarousel?.current?.goTo(0);
    myFavCarousel?.current?.goTo(0);
    myPDCarousel?.current?.goTo(0);

    setSelectedRegion(reg);
    sessionStorage.setItem("selectedRegion", JSON.stringify(reg));
    props.setRegion(reg);
    parseData(dashboardData, reg);
    getFavorites();
  }

  /*EMEA Release- - Added handler for region selection- radiobutton  */
  const regionSelection = (region) => {
    if (JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase() === region.toLowerCase())
      return true;
    else {
      return false;
    }
  }

  const processBannerData = (fetchedData) => {
    fetchedData.sort(utils.sorting('page', 'string', true));
    props.setBanner(fetchedData);
  }

  const [isBannerLoading, fetchedData] = useHttpGet(
    `filePath=kpi-configuration&fileName=banner-information.json`,
    [],
    processBannerData
  );

  const processAckData = (ackData) => {
    setAckData(ackData);
  }

  const [isAckLoading, ackData] = useHttpGet(
    `filePath=dashboard&fileName=ack-information.json`,
    [],
    processAckData
  );

  const checkIsAckAcknowledged = () => {
    let flag = true;
    if (acknowledgeData && acknowledgeData.length > 0) {
      acknowledgeData.forEach(element => {
        if (element.id === props.userInfo) {
          flag = false;
        }
      });
    }
    return flag;
  }
  const handleAckSubmit = () => {
    setAckFlagUpdate(true);
    const data = {
      id: props.userInfo,
      isAcknowledged: true
    }
    utils.getData('editackinformation', 'POST', data)
      .then(resp => {
        setAckFlagUpdate(false);
        handleClose();
      })
      .catch(error => {
        console.log(error);
        setAckFlagUpdate(false);
      })
  }
  async function removeFavorite(e, favorite) {
    try {
      setLoading(true)
      e.stopPropagation()
      await favUtils.removeFavorites(favorite.FavoriteId)
      await getFavorites()
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }

  const productBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2},
    { width: 1020, itemsToShow: 4, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 3 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 3 }
  ]
  const customerBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1120, itemsToShow: 5, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 6, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 7, itemsToScroll: 6 }
  ]

  const subRegionBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850,  itemsToShow: 3, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 5, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 6, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 7, itemsToScroll: 6 }
  ]

  const favoriteBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 5, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 6, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 7, itemsToScroll: 6 }
  ]
 
  const initialLoadArrow = ()=> {
    document.querySelector("#customerContainer #scrollToEnd").style.display = "none";
    document.querySelector("#customerContainer #scrollToStart").style.display = "none";
    document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "none";
    document.querySelector("#favoriteContainer #favScrollToStart").style.display = "none";
  }

  const showHideLeftSRArrow = ()=>{
    //document.querySelector("#subRegionContainer #subRegionScrollToEnd").style.display = "none";
    if(document.querySelector("#subRegionContainer .rec.rec-arrow-left")){
      //document.querySelector("#subRegionContainer #subRegionScrollToEnd").style.display = "none";
      if(document.querySelector("#subRegionContainer .rec.rec-arrow-left").disabled){
        document.querySelector("#subRegionContainer #subRegionScrollToEnd").style.display = "block";
      }
      else{
        document.querySelector("#subRegionContainer #subRegionScrollToEnd").style.display = "none";
     }
    }
  }
 
  const showHideRightSRArrow = ()=>{
    //document.querySelector("#subRegionContainer #subRegionScrollToStart").style.display = "none";
    if(document.querySelector("#subRegionContainer .rec.rec-arrow-right")){
      //document.querySelector("#subRegionContainer #subRegionScrollToStart").style.display = "none";
      if(document.querySelector("#subRegionContainer .rec.rec-arrow-right").disabled){
       document.querySelector("#subRegionContainer #subRegionScrollToStart").style.display = "block";
      }
      else{
        document.querySelector("#subRegionContainer #subRegionScrollToStart").style.display = "none";
      }
    }
  }

  const showHideLeftCustArrow = ()=>{
    document.querySelector("#customerContainer #scrollToEnd").style.display = "none";
    if(document.querySelector("#customerContainer .rec.rec-arrow-left")){
      document.querySelector("#customerContainer #scrollToEnd").style.display = "none";
      if(document.querySelector("#customerContainer .rec.rec-arrow-left").disabled){
        document.querySelector("#customerContainer #scrollToEnd").style.display = "block";
      }
      else{
        document.querySelector("#customerContainer #scrollToEnd").style.display = "none";
     }
    }
  }
 
  const showHideRightCustArrow = ()=>{
    document.querySelector("#customerContainer #scrollToStart").style.display = "none";
    if(document.querySelector("#customerContainer .rec.rec-arrow-right")){
      document.querySelector("#customerContainer #scrollToStart").style.display = "none";
      if(document.querySelector("#customerContainer .rec.rec-arrow-right").disabled){
       document.querySelector("#customerContainer #scrollToStart").style.display = "block";
      }
      else{
        document.querySelector("#customerContainer #scrollToStart").style.display = "none";
      }
    }
  }

  const showHideLeftFavArrow = ()=>{
    document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "none";//document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "block";
    if(document.querySelector("#favoriteContainer .rec.rec-arrow-left") && favorites.length>5){
   //   document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "none";
      if(document.querySelector("#favoriteContainer .rec.rec-arrow-left").disabled){
        document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "block";
      }
      else{
        document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "none";
     }
    }
  }

  const showHideRightFavArrow = ()=>{
    document.querySelector("#favoriteContainer #favScrollToStart").style.display = "none";
    if(document.querySelector("#favoriteContainer .rec.rec-arrow-right") && favorites.length>5){
      document.querySelector("#favoriteContainer #favScrollToStart").style.display = "none";
      if(document.querySelector("#favoriteContainer .rec.rec-arrow-right").disabled){
       document.querySelector("#favoriteContainer #favScrollToStart").style.display = "block";
      }
      else{
        document.querySelector("#favoriteContainer #favScrollToStart").style.display = "none";
      }
    }
  }
  
  const onClickSRScrollToStart = ()=>{
    document.querySelector("#subRegionContainer #subRegionScrollToStart").style.display = "none";
    mySRCarousel?.current?.goTo(0);
   }

   const onClickSRScrollToEnd = ()=>{/*Left Cust Arrow- which takes to dead end */
   document.querySelector("#subRegionContainer #subRegionScrollToEnd").style.display = "none";
   mySRCarousel?.current?.goTo(subRegionData.length);
 }

  const onClickCustScrollToStart = ()=>{
    document.querySelector("#customerContainer #scrollToStart").style.display = "none";
    myCustCarousel?.current?.goTo(0);
   }

   const onClickCustScrollToEnd = ()=>{/*Left Cust Arrow- which takes to dead end */
   document.querySelector("#customerContainer #scrollToEnd").style.display = "none";
   myCustCarousel?.current?.goTo(data.customers.length);
 }

  const onClickFavScrollToEnd = ()=>{/*Left Fav Arrow- which takes to dead end */
  document.querySelector("#favoriteContainer #favScrollToEnd").style.display = "none";
  myFavCarousel?.current?.goTo(favorites.length);
  }

  const onClickFavScrollToStart = ()=>{
    document.querySelector("#favoriteContainer #favScrollToStart").style.display = "none";
    myFavCarousel?.current?.goTo(0);
  }


 const myCustCarouselChange=(current, pagenum)=>{
  showHideLeftCustArrow();
  showHideRightCustArrow();
}

const myFavCarouselChange=(current, pagenum)=>{
  showHideLeftFavArrow();
  showHideRightFavArrow();
}

const mySubRegionCarouselChange=(current, pagenum)=>{
  showHideLeftSRArrow();
  showHideRightSRArrow();
}

  return (
    <Spin spinning={isLoading || loading || isBannerLoading || isAckLoading} >
      {
        props && props.userInfo && checkIsAckAcknowledged() ? (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span className={styles.ackTitle}>
                  Acknowledgement Confirmation
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              VOICE Platform is company confidential and for internal use only. Any information from VOICE should never be shared with customers or other external stakeholders.
            </Modal.Body>
            <Modal.Footer>
              <span className={styles.spinAlign}>
                <Spin spinning={isAckUpdated}></Spin>
              </span>
              <Button variant="primary" className={styles.customBtn} onClick={() => handleAckSubmit()}>I acknowledge</Button>
            </Modal.Footer>
          </Modal>
        ) : null
      }
      <div className={styles.container}>
        {data && data.group ? (<>
          {/*<div className={styles.dummyWrapper}></div>*/}
          <div className={styles.radioWrapper}>
            <span className={styles.radioLabel}>Select Region </span>
            <Form className={styles.radioForm}>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  defaultChecked={regionSelection("NA")}
                  className={styles.formRadioInline}
                  label="NA"
                  name="region"
                  type="radio"
                  onClick={() => handleRegionChange("NA")}
                  id={`inline-radio-1`} />
                <Form.Check
                  inline
                  defaultChecked={regionSelection("EMEA")}
                  className={styles.formRadioInline}
                  label="EMEA"
                  name="region"
                  type="radio"
                  onClick={() => handleRegionChange("EMEA")}
                  id={`inline-radio-2`} />
              </div>

            </Form>
          </div>
        </>) : null
        }
        <div className={styles.productsContainer}>
          {
            data && data.group ? (
              <div >
                <Title value="Group" count={null} />
                <Product
                  product={data.group}
                  {...props}
                  type="product"
                  showDetail={() => showGroupDetail(data.group)} />
              </div>
            ) : null
          }
          {data && data.productDivisions && data.productDivisions.length ? (
            <div className={styles.productBox} >
              <Title value="Product Divisions​" count={data.productDivisions.length} />
              <div className="outerGrid">
                <Carousel ref={myPDCarousel} breakPoints={productBreakPoints}>
                  {data.productDivisions.map((item, index) => {
                    return (
                      <Product
                        key={index}
                        product={item}
                        {...props}
                        type="productdivision"
                        showDetail={setProductDivision}
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : null}
        </div>
         {/*EMEA SubRegion Section */}
         <div id="subRegionContainer" className={styles.productsContainer}>
         {selectedRegion=='EMEA' && subRegionData && subRegionData.length>0 && <div className={styles.scrollEndParent}>
           <div id="subRegionScrollToEnd" className={`${styles.leftSRArrow} ${styles.buttonStyle} `} onClick={() => onClickSRScrollToEnd()}>{"❮"}</div>
          </div> }
         <div className={styles.productBox}>
          {subRegionData && subRegionData.length>0 ? (
            <div className={`row no-gutters ${styles.customerLayoutBx} ${styles.customerList} ${styles.tilePaddingbottom} ${styles.tilePaddingtop}`}>
              <Title value="Sub-regions" count={subRegionData.length} />
              <Carousel ref={mySRCarousel}
                  onChange={(currentItem, pageIndex) =>
                  mySubRegionCarouselChange(currentItem, pageIndex)}  
                  breakPoints={subRegionBreakPoints}>
                {subRegionData?.map((item, index) => {
                  return (
                    <Product
                      key={index}
                      product={item}
                      {...props}
                      type="subregion"
                      showDetail={setSubRegion}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}
          </div>
          {selectedRegion==='EMEA' && subRegionData && subRegionData.length>0 && <div className={styles.scrollStartParent}>
           <div id="subRegionScrollToStart" className={`${styles.rightSRArrow} ${styles.buttonStyle} `} onClick={() => onClickSRScrollToStart()}>{"❯"}</div>
          </div>}
        </div> 
        <div id="customerContainer" className={styles.productsContainer}>
          {<div className={styles.scrollEndParent}>
           <div id="scrollToEnd" className={`${styles.leftArrow} ${styles.buttonStyle} `} onClick={() => onClickCustScrollToEnd()}>{"❮"}</div>
          </div>}
        
          {data && data.customers && data.customers.length ? (
            <div className={`row no-gutters ${styles.customerLayoutBx} ${styles.customerList} ${styles.tilePaddingbottom}`}>
              <Title value="Customers" count={data.customers.length} />
              <Carousel  ref={myCustCarousel}
                onChange={(currentItem, pageIndex) =>
                myCustCarouselChange(currentItem, pageIndex)}  
                breakPoints={customerBreakPoints}>
                {data.customers.map((item, index) => {
                  return (
                    <Product
                      key={index}
                      product={item}
                      {...props}
                      type="customer"
                      showDetail={showCustomerDetail}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}
          {<div className={styles.scrollStartParent}>
           <div id="scrollToStart" className={`${styles.rightArrow} ${styles.buttonStyle} `} onClick={() => onClickCustScrollToStart()}>{"❯"}</div>
          </div>}
        
        </div>
        <div id="favoriteContainer" className={styles.productsContainer} >
         {
          <div className={styles.scrollEndParent}>
           <div id="favScrollToEnd" className={`${styles.leftArrow} ${styles.buttonStyle} `} onClick={() => onClickFavScrollToEnd()}>{"❮"}</div>
          </div>
         }
          {favorites.length ? (
            <div className={styles.customerLayoutBx}>
              <Title value="Favorites" count={favorites.length} />
              <Carousel ref={myFavCarousel} 
              onChange={(currentItem, pageIndex) =>
                myFavCarouselChange(currentItem, pageIndex)}  
                breakPoints={favoriteBreakPoints}>
                {favorites.map((item, index) => {
                  return (
                    <Favorite
                      key={index}
                      favorite={item}
                      {...props}
                      type="customer"
                      removeFavorite={removeFavorite}
                      showDetail={viewFavorites}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}
         {<div className={styles.scrollStartParent}>
           <div id="favScrollToStart" className={`${styles.rightArrow} ${styles.buttonStyle} `} onClick={() => onClickFavScrollToStart()}>{"❯"}</div>
          </div>} 
        </div>
      </div>
    </Spin >)
};

export default Dashboard;